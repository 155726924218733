<template>
  <v-row>
     <v-col cols="4" sm="4" md="4" class="text-left mt-4">
     <v-toolbar-title class="pl-5">{{titleInfo}}</v-toolbar-title>
    <v-spacer></v-spacer>  
    </v-col>        
    <v-col cols="2" sm="2" md="2" class="text-right pl-5">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pl-5"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="ចាប់ផ្តើម"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="2"
      sm="2"
      md="2"
      class="text-right pl-5 "
    >
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        label="បញ្ចប់"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
      <v-date-picker
        v-model="endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2" sm="2" md="2" class="text-right pl-5">
      <v-text-field
        v-model="v_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
         class="pl-5"
      />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="2" sm="2" md="2" class="text-right pr-5">
      <v-btn 
        class="white--text btn-custom mr-5"
        color="green darken-1"
        depressed
        @click="getData()"
      >
        SEARCH
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from "vuex";
export default {
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission',"user"],
  data() {
    return {
      data: {
        descending: true,
        sortBy: '_id',
        page: 1,
        rowsPerPage: 10,
        fields: ["_id.user_name", "_id.balance", "amount", "amount_win"],
        search: '',
        startDate:  new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      startDate:  new Date().toISOString().substr(0, 10),
      endDate:  new Date().toISOString().substr(0, 10),
      v_startDate: null,
      v_endDate: null,
      v_search:"",
    };
  },
  

 computed: {
   titleInfo: function(){
      if( this.user){
          if(  this.user.role_name == "DEVELOPER") return "List Company Statement"
          else if( this.user.role_name == "COMPANY") return "List Senior Statement"
          else if( this.user.role_name == "MANAGER") return "List Senior Statement"
          else if( this.user.role_name == "SENIOR") return "List Master Statement"
          else if( this.user.role_name == "MASTER") return "List Agent Statement"
          else if( this.user.role_name == "AGENT") return "List Player Statement"
          else return "Loading..."
      }
      else return "Loading..."
    },
    reportUrl: function(){

     if(this.user){
        if(this.user.role_name == "DEVELOPER") return "getViewCompanyKh"
        else if(this.user.role_name == "COMPANY") return "getViewSenior"
        else if(this.user.role_name == "MANAGER") return "getViewSenior"
        else if(this.user.role_name == "SENIOR") return "getViewMaster"
        else if(this.user.role_name == "MASTER") return "getViewAgent"
        else if(this.user.role_name == "AGENT") return "getViewPlayer"
        else return null
     }
     else return null
    },
 },
 watch: {
    checkbox: function() {
      // Emit this information to the parents component
      this.$emit("child-checkbox", this.checkbox);
    }
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },
    async getData() {
      this.data.startDate =this.startDate
      this.data.endDate =this.endDate
      this.data.search = this.v_search
      let preData = {
        data : this.data,
        user_id : this.user._id,
        reportUrl : this.reportUrl
      }
      this.fetchListStatement(preData).then(() => {
            let date = {
              startDate : this.startDate,
              endDate : this.endDate
            }
           this.$emit("child-checkbox", date);
          })
    },
    ...mapActions("$_statement", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom{
        position: relative;
        // right: 250px;
        top: 10px;        
    }
</style>
